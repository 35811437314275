import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { OrderType, TradeDirection } from "src/api/types";
import CButton from "src/component/CButton/CButton";
import styles from "./index.module.scss";
import CInput from "src/component/CInput/CInput";
import { useState } from "react";
import CField from "src/component/CField/CField";
import OpenPositionAlert from "./OpenPositionAlert";

export default function MarketOpenPosition() {
  const [tabIndex, setTabIndex] = useState(0);
  const [tradeDirection, setTradeDirection] = useState<TradeDirection>(TradeDirection.Buy);
  const orderType = tabIndex === 0 ? OrderType.LIMIT : OrderType.MARKET;

  const handleSetTabIndex = (index: number) => {
    setTabIndex(index);
  };
  // open alert dialog
  const {
    isOpen: isOpenPositionAlertOpen,
    onClose: onCloseOpenPositionAlert,
    onOpen: onOpenOpenPositionAlert,
  } = useDisclosure();

  return (
    <Formik
      initialValues={{
        openPrice: "",
        amount: "",
      }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue, submitForm }) => {
        const fieldComponent = (
          <>
            <Flex flexDir={"column"} gap={2} mt={4} width="100%" justifyContent="space-between">
              <CInput classname={styles.inputField} label="开仓数量" name="amount" type="number" />
              <CField label="倍数" value="10x" />
            </Flex>
            <Flex alignItems="center" gap={2} mt={4} width={"100%"}>
              <CButton
                name="开多"
                colorScheme={"teal"}
                onClick={() => {
                  setTradeDirection(TradeDirection.Buy);
                  onOpenOpenPositionAlert();
                }}
              />
              <CButton
                name="开空"
                colorScheme={"red"}
                onClick={() => {
                  setTradeDirection(TradeDirection.Sell);
                  onOpenOpenPositionAlert();
                }}
              />
            </Flex>
            <OpenPositionAlert
              isOpen={isOpenPositionAlertOpen}
              onClose={onCloseOpenPositionAlert}
              price={tabIndex === 0 ? Number(values.openPrice) : 0}
              margin={Number(values.amount)}
              leverage={10}
              side={tradeDirection}
              orderType={orderType}
            />
          </>
        );
        return (
          <>
            <Form className={styles.openForm}>
              <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
                <Tabs onChange={(index) => handleSetTabIndex(index)}>
                  <TabList>
                    <Tab>限价开单</Tab>
                    <Tab>市价开单</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Flex gap={2} mt={4} width="100%" flexDir={"column"}>
                        <CInput classname={styles.inputField} label="开仓价格" name="openPrice" type="number" />
                        {fieldComponent}
                      </Flex>
                    </TabPanel>
                    <TabPanel>
                      <Flex gap={2} mt={4} width="100%" flexDir={"column"}>
                        <CField label="开仓价格" value="市价" classname={styles.inputField} />
                        {fieldComponent}
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
