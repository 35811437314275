import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
  Flex,
  Text,
} from "@chakra-ui/react";
import { on } from "events";
import React, { useMemo } from "react";
import { ubPerpApi } from "src/api";
import { FetchPositionResponse } from "src/api/types";
import CSwitch from "src/component/CSwitch/CSwitch";
import { useMessage } from "src/hook/useMessage";
import { useExchangeInfoStore } from "src/store";

type ClosePositionAlertProps = {
  positionItem: FetchPositionResponse | null;

  isOpen: boolean;
  onClose: () => void;
  submitForm: (payload: { amount: number }) => void;
};

export default function ClosePositionAlert(props: ClosePositionAlertProps) {
  const cancelRef = React.useRef();
  const message = useMessage();
  const store = useExchangeInfoStore();
  const { selectedExchangeKey, selectedUbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);

  const { isOpen, onClose, positionItem } = props;
  const [amount, setAmount] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [isMarketPrice, setIsMarketPrice] = React.useState(false);

  const handleClose = () => {
    setAmount(0);
    setPrice(0);
    setIsMarketPrice(false);
    onClose();
  };

  const handleClosePosition = async () => {
    try {
      const param = {
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
        symbol: positionItem.symbol,
        closePrice: isMarketPrice ? 0 : price,
        amount: amount,
        side: positionItem.side,
      };
      console.log("handleClosePosition:", param);
      await ubPerpApi.requestClosePosition(param);
      message.success("平仓成功");
    } catch (error) {
      message.detailedError(error);
    }
  };
  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              平仓
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex flexDir={"column"} gap={4}>
                <Flex alignItems={"center"}>
                  <Text width={"180px"}> 请输入数量</Text>
                  <Input
                    placeholder="请输入数量"
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    type="number"
                  />
                </Flex>
                <CSwitch
                  label={"市价平仓"}
                  isChecked={isMarketPrice}
                  onChange={(id, value) => setIsMarketPrice(value)}
                />
                <Flex alignItems={"center"}>
                  <Text width={"180px"}> 请输入价格</Text>
                  <Input
                    disabled={isMarketPrice}
                    placeholder="请输入价格"
                    value={isMarketPrice ? "0" : price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                    type="number"
                  />
                </Flex>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  handleClose();
                }}
              >
                取消
              </Button>
              <Button
                onClick={async () => {
                  await handleClosePosition();
                  handleClose();
                }}
                ml={3}
              >
                确定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
