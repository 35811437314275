import { TableContainer, Table, Tr, Tbody, Td, Th, Thead, Tfoot } from "@chakra-ui/react";
import styles from "./Ctable.module.scss";
import classNames from "classnames";
import { Pagination } from "./Pagination";
import { useEffect, useState } from "react";
type TableProps = {
  classname?: string;
  items: Array<any>;
  cols: Array<{
    key: string;
    render?: (item: any) => JSX.Element;
    title?: string;
  }>;
  showHeader?: boolean;
  operationCol?: (item: any) => React.ReactNode;
  operationSide?: "left" | "right";
  paginated?: boolean;
};

export default function CTable(props: TableProps) {
  const { operationCol, items, paginated } = props;
  const [showItems, setShowItems] = useState(items);
  const isOperationSideOnLeft = props.operationSide === "left";
  const [page, setPage] = useState(1);
  const onPageChange = (page: number) => {
    setPage(page);
  };
  useEffect(() => {
    if (paginated) {
      const start = (page - 1) * 10;
      const end = start + 10;
      setShowItems(items.slice(start, end));
    } else {
      setShowItems(items);
    }
  }, [page, items, paginated]);

  return (
    <TableContainer width="100%" className={props.classname}>
      <Table variant="simple">
        {props.showHeader && (
          <Thead>
            <Tr>
              {operationCol && <Th>操作</Th>}
              {props.cols.map((col, index) => {
                return <Th key={`${JSON.stringify(col)}-${index}`}>{col.title}</Th>;
              })}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {showItems.map((item, index) => {
            return (
              <Tr
                key={`${JSON.stringify(item)}-${index}`}
                className={classNames({ [styles.green]: item.isGreen, [styles.red]: item.isRed })}
              >
                {operationCol && <Td>{operationCol(item)}</Td>}
                {props.cols.map((col, index) => {
                  return (
                    <Td key={`${JSON.stringify(col)}-${index}`}>{col.render ? col.render(item) : item[col.key]}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
        {paginated && (
          <Tfoot>
            <Tr>
              <Td colSpan={10}>
                <Pagination total={items.length} pageSize={10} currentPage={page} onPageChange={onPageChange} />
              </Td>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
}

type Token = {
  symbol: string;
  tradingViewSymbol: string;
  isTradingViewWidgetSupported: boolean;
};
const TokenList = [
  {
    symbol: "BTC",
    tradingViewSymbol: "CRYPTO:BTCUSD",
    isTradingViewWidgetSupported: true,
  },
];
