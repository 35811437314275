import { Flex, Input, InputGroup, InputRightAddon, Text, Tooltip } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import { useField } from "formik";
import styles from "./CInput.module.scss";
import React from "react";
import classNames from "classnames";

const CInput = ({
  label,
  required,
  classname,
  description,
  onChange,
  rightIndicator,
  ...props
}: {
  label: string;
  required?: boolean;
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  classname?: string;
  description?: string;
  rightIndicator?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [isFocus, setIsFocus] = React.useState(false);
  const [field, meta, helper] = useField(props as any);
  const fieldId = props.id || props.name;
  return (
    <Flex maxWidth="800px" gap={1} flexDirection="column" alignItems="center" className={classname}>
      <Flex gap={2} alignItems="center" width="100%" justifyContent="space-between">
        <Flex gap={1} alignItems="center">
          <Text
            as="label"
            htmlFor={fieldId}
            width="max-content"
            maxW="600px"
            className={classNames({ [styles.focused]: isFocus })}
          >
            {label}
            {": "}
            {required && (
              <Text as="span" color="red.500">
                *
              </Text>
            )}
          </Text>
          {description && (
            <Tooltip label={description} aria-label="A tooltip">
              <QuestionIcon cursor="pointer" />
            </Tooltip>
          )}
        </Flex>
        <InputGroup size="sm" borderColor={rightIndicator && rightIndicator === "U" ? "red" : "none"}>
          <Input
            className="text-input"
            {...field}
            {...props}
            width="auto"
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={(e) => {
              helper.setValue(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
          />
          {rightIndicator && <InputRightAddon>{rightIndicator}</InputRightAddon>}
        </InputGroup>
      </Flex>
      {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
    </Flex>
  );
};

export default CInput;
