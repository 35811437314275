import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import AccountSelect from "src/component/AccountSelect/AccountSelect";
import ExchangeSelect from "src/component/ExchangeSelect/ExchangeSelect";
import PairSelect from "src/component/PairSelect/PairSelect";
import TradingViewWidget from "src/component/TradingViewWidget";
import { useExchangeInfoStore } from "src/store";
import { getPairSymbol, getTradingviewCexName } from "src/store/utils";
import { useMessage } from "src/hook/useMessage";
import { isSelectedEmpty } from "src/utils/states";
import { orderApi, ubPerpApi } from "src/api";
import { useQuery } from "@tanstack/react-query";
import MarketOpenPosition from "./MarketOpenPosition";
import CTable from "src/component/CTable/Ctable";
import { orderCols, positionCols, REFETCH_INTERVAL } from "./perpConfigs";
import CButton from "src/component/CButton/CButton";
import { ErrorMessage } from "src/utils/ErrorMessage";
import { FetchOrderResponse, FetchPositionResponse, TradeType } from "src/api/types";
import ClosePositionAlert from "./ClosePositionAlert";

export default function Perpeptual() {
  const store = useExchangeInfoStore();
  const message = useMessage();
  const { selectedExchangeKey, selectedUbPerpPair: selectedPair } = store;
  const selectedExchangeAccountId = useExchangeInfoStore((state) => state.selectedExchangeAccountId);
  const [selectedPosition, setSelectedPosition] = useState<FetchPositionResponse | null>(null);
  const enableQuery = useMemo(() => {
    return !isSelectedEmpty(selectedExchangeAccountId) && !!selectedExchangeKey && !!selectedPair;
  }, [selectedExchangeAccountId, selectedExchangeKey, selectedPair]);

  // log exchange name and pair name, exchange account id
  console.log("Perpeptual selectedExchangeKey: ", selectedExchangeKey);
  console.log("Perpeptual selectedPair: ", selectedPair);
  console.log("Perpeptual selectedExchangeAccountId: ", selectedExchangeAccountId);
  console.log("Perpeptual enableQuery: ", enableQuery);

  const balaceQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["balance", selectedExchangeAccountId],
    queryFn: () =>
      ubPerpApi.fetchPerpBalance({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
      }),
    refetchInterval: REFETCH_INTERVAL,
  });
  const ordersQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["orders", selectedExchangeAccountId],
    queryFn: () =>
      ubPerpApi.fetchPerpOrders({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
      }),
    refetchInterval: REFETCH_INTERVAL,
  });
  const positionQuery = useQuery({
    enabled: enableQuery,
    queryKey: ["position", selectedExchangeAccountId],
    queryFn: () =>
      ubPerpApi.fetchPosition({
        exchangeAccountId: selectedExchangeAccountId,
        exchangeName: selectedExchangeKey,
      }),
    refetchInterval: REFETCH_INTERVAL,
  });

  const {
    isOpen: isClosePositionAlertOpen,
    onClose: onCloseClosePositionAlert,
    onOpen: onOpenClosePositionAlert,
  } = useDisclosure();

  console.log("Perpeptual balaceQuery: ", balaceQuery.data);
  console.log("Perpeptual ordersQuery: ", ordersQuery.data);
  console.log("Perpeptual positionQuery: ", positionQuery.data);
  const positionOperationCol = (item: any) => {
    return (
      <Flex>
        <CButton
          colorScheme={"red"}
          name={"平仓"}
          onClick={() => {
            setSelectedPosition(item);
            onOpenClosePositionAlert();
          }}
        />
      </Flex>
    );
  };

  const orderOperationCol = (item: FetchOrderResponse) => {
    return (
      <Flex>
        <CButton
          colorScheme={"red"}
          name={"取消"}
          onClick={async () => {
            try {
              await ubPerpApi.requestCancelOrder({
                exchangeName: selectedExchangeKey,
                exchangeAccountId: selectedExchangeAccountId,
                orderId: item.id,
                type: item.type,
                symbol: item.symbol,
              });
            } catch (error) {
              // show error
              message.detailedError(<ErrorMessage error={error as any} />);
            }
          }}
        />
      </Flex>
    );
  };

  return (
    <>
      <Flex width="100%" maxH="calc(100vh - 140px)" px={2} justifyContent="start" gap={12} flexDir={"column"}>
        <Flex width={"100%"} height={"auto"}>
          <Flex
            width="calc(50% - 20px)"
            maxW="600px"
            flexDirection="column"
            overflow="scroll"
            gap={2}
            style={{ scrollbarWidth: "none" }}
          >
            <Flex alignItems="center" gap={2}>
              <PairSelect tradeType={TradeType.UB_PERP} />
            </Flex>
            <Flex alignItems="center" gap={2}>
              <ExchangeSelect tradeType={TradeType.UB_PERP} />
            </Flex>
            <Flex alignItems="center" gap={2}>
              <AccountSelect />
            </Flex>
            <Flex>余额： {balaceQuery.data}</Flex>
            <Flex alignItems="center" gap={2}>
              <MarketOpenPosition />
            </Flex>
          </Flex>
          <Flex width="calc(50% - 20px)" flexGrow={1} flexDirection="column">
            <Flex height="400px" mb={8}>
              <TradingViewWidget
                pairName={getPairSymbol(selectedPair?.base, selectedPair?.quote)}
                cexName={getTradingviewCexName(selectedExchangeKey)}
                isPerpetual={true}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex width="100%" flexDirection="column" gap={2}>
          <Tabs>
            <TabList>
              <Tab>当前委托</Tab>
              <Tab>当前持仓</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <CTable
                  items={ordersQuery.data || []}
                  cols={orderCols}
                  showHeader
                  operationSide="left"
                  operationCol={orderOperationCol}
                />
              </TabPanel>
              <TabPanel>
                <CTable
                  items={positionQuery.data || []}
                  cols={positionCols}
                  showHeader
                  operationSide="left"
                  operationCol={positionOperationCol}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <ClosePositionAlert
        positionItem={selectedPosition}
        isOpen={isClosePositionAlertOpen}
        onClose={onCloseClosePositionAlert}
        submitForm={function (payload: { amount: number }): void {
          throw new Error("Function not implemented.");
        }}
      />
    </>
  );
}
