import { Flex } from "@chakra-ui/react";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { strategiesApi } from "src/api";
import {
  Intervals,
  LowBuyHighSellExampleType,
  StrategyResponse,
  StrategyType,
  UpdateStrategyParams,
} from "src/api/types";
import { useExchangeInfoStore } from "src/store";
import CButton from "../CButton/CButton";
import CInput from "../CInput/CInput";
import styles from "./StrategyDetail.module.scss";
import * as Yup from "yup";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import CField from "src/component/CField/CField";
import { useMessage } from "src/hook/useMessage";
import CommonLowBuyHighSellForm from "../StrategyForms/CommonLowBuyHighSellForm";
import CSelect from "../CSelect";
import { ErrorMessage } from "src/utils/ErrorMessage";

type Props = {
  strategy: StrategyResponse<LowBuyHighSellExampleType>;
  onUpdated?: () => void;
};

export default function LowBuyHighSellDetail(props: Props) {
  const { strategy, onUpdated } = props;
  const message = useMessage();
  const accounts = useExchangeAccounts();
  const pairs = useExchangeInfoStore((state) => state.spotPairs);
  const pair = pairs.find((pair) => pair.id === strategy.pairId);
  const exchangeAccount = accounts.allExchangeAccounts.find((account) => account.id === strategy.exchangeAccountId);
  const intervalOptions = [
    { value: "5m", label: "5m" },
    { value: "15m", label: "15m" },
    { value: "30m", label: "30m" },
    { value: "1h", label: "1h" },
  ];
  return (
    <Formik
      initialValues={{
        strategyName: strategy.strategyName,
        repeatIntervalMsMin: strategy.repeatIntervalMsMin,
        repeatIntervalMsMax: strategy.repeatIntervalMsMax,

        interval: intervalOptions.find((option) => option.value === strategy.preference.interval),
        buyAmount: strategy.preference.buyAmount,
        buyCount: strategy.preference.buyCount,
        dropPerOfBenefits: strategy.preference.dropPerOfBenefits,
        perOfBenefits: strategy.preference.perOfBenefits,
        round4: strategy.preference.round4,
        priceLow4: strategy.preference.priceLow4,
        priceHigh4: strategy.preference.priceHigh4,
      }}
      validationSchema={Yup.object({
        strategyName: Yup.string().required("Required"),
      })}
      onSubmit={async (values) => {
        console.log("====================================");
        console.log("values", values);
        console.log("====================================");
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
              <Flex alignItems="center" gap={2} mt={2}>
                <CField label="间隔时间" value={String(values.interval.value)} />
              </Flex>
              <CommonLowBuyHighSellForm styles={styles} />
              <Flex alignItems="center" gap={2} mt={4}>
                <CButton
                  name="保存"
                  type="submit"
                  onClick={async () => {
                    const payload: UpdateStrategyParams<LowBuyHighSellExampleType> = {
                      strategyId: strategy.id,
                      strategyName: values.strategyName,
                      repeatIntervalMsMin: values.repeatIntervalMsMin,
                      repeatIntervalMsMax: values.repeatIntervalMsMax,
                      preference: {
                        type: StrategyType.LowBuyHighSell,
                        interval: values.interval.value as Intervals,
                        buyAmount: Number(values.buyAmount),
                        buyCount: Number(values.buyCount),
                        dropPerOfBenefits: Number(values.dropPerOfBenefits),
                        perOfBenefits: Number(values.perOfBenefits),
                        round4: Number(values.round4),
                        priceLow4: Number(values.priceLow4),
                        priceHigh4: Number(values.priceHigh4),
                      },
                    };
                    try {
                      const result = await strategiesApi.updateStrategyV2(payload);
                      message.success("更新成功");
                      onUpdated && onUpdated();
                    } catch (error) {
                      message.detailedError(<ErrorMessage error={error} />);
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
