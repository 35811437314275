export type PairType = {
  id: number;
  base: string;
  quote: string;
  exchanges?: string[];
};

export const PAIRS: PairType[] = [
  {
    id: 1,
    base: "BTC",
    quote: "USDT",
  },
];
