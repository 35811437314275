import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { strategiesApi } from "src/api";
import {
  CreateStrategyParams,
  Intervals,
  LowBuyHighSellExampleType,
  StrategyKey,
  StrategyType,
  TradeDirection,
} from "src/api/types";
import CButton from "src/component/CButton/CButton";
import CSelect from "src/component/CSelect";
import { useExchangeInfoStore } from "src/store";
import { getPairName } from "src/store/utils";
import * as Yup from "yup";
import styles from "./index.module.scss";
import { useState } from "react";
import { useExchangeAccounts } from "src/hook/useExchangeAccounts";
import React from "react";
import CommonLowBuyHighSellForm from "src/component/StrategyForms/CommonLowBuyHighSellForm";

export default function LowBuyHighSellForm() {
  const store = useExchangeInfoStore();
  const exchanges = useExchangeAccounts();
  const [selectedExchangeAccountId, setSelectedExchangeAccountId] = useState<number | null>(null);

  const exchangeAccountOptions = exchanges.myExchangeAccounts.map((account) => {
    const exchange = store.exchanges.find((exchange) => exchange.id === account.exchangeId);
    const displayName = `${exchange ? exchange.name : "unknown"}: ${account.alias}`;
    return { value: account.id, label: displayName };
  });

  const pairOptions = store.spotPairs.map((pair) => ({
    value: pair.id,
    label: getPairName(pair.base, pair.quote),
  }));
  const intervalOptions = [
    { value: "5m", label: "5m" },
    { value: "15m", label: "15m" },
    { value: "30m", label: "30m" },
    { value: "1h", label: "1h" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  return (
    <Formik
      initialValues={{
        strategyName: "",
        pair: "",
        exchangeAccount: "",
        repeatIntervalMsMin: "10000",
        repeatIntervalMsMax: "20000",

        interval: { value: "5m", label: "5m" },

        // buyAmount: number;
        // buyCount: number;
        // dropPerOfBenefits: number;
        // perOfBenefits: number;
        buyAmount: "",
        buyCount: "",
        dropPerOfBenefits: "",
        perOfBenefits: "",
        round4: "1",
        priceLow4: "",
        priceHigh4: ""
      }}
      validationSchema={Yup.object({
        pair: Yup.object().required("Required"),
        exchangeAccount: Yup.object().required("Required"),
      })}
      onSubmit={async (values) => {
        const payload: CreateStrategyParams<LowBuyHighSellExampleType> = {
          strategyName: values.strategyName,
          pairId: Number((values.pair as any).value),
          exchangeAccountId: Number((values.exchangeAccount as any).value),
          type: StrategyType.LowBuyHighSell,
          repeatIntervalMsMin: Number(values.repeatIntervalMsMin),
          repeatIntervalMsMax: Number(values.repeatIntervalMsMax),
          preference: {
            type: StrategyType.LowBuyHighSell,
            interval: values.interval.value as Intervals,
            buyAmount: Number(values.buyAmount),
            buyCount: Number(values.buyCount),
            dropPerOfBenefits: Number(values.dropPerOfBenefits),
            perOfBenefits: Number(values.perOfBenefits),
            round4: Number(values.round4),
            priceLow4: Number(values.priceLow4),
            priceHigh4: Number(values.priceHigh4),
          },
        };
        console.log("payload", payload);

        try {
          const result = await strategiesApi.createStrategyV2(payload);
          window.location.href = `/strategies?tab=${StrategyKey.LowBuyHighSell}`;
        } catch (error) {
          alert("创建失败");
        }
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <>
            <Form className={styles.form}>
              <Flex width="calc(100% - 20px)" flexDirection="column" gap={2}>
                <Flex alignItems="center" gap={2} mt={4}>
                  <CSelect
                    classname={styles.select}
                    label={"交易所账户"}
                    placeholder="交易所账户"
                    options={exchangeAccountOptions}
                    onChange={(value) => {
                      setFieldValue("exchangeAccount", value);
                      setSelectedExchangeAccountId((value as any).value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"交易对"}
                    placeholder="交易对"
                    options={pairOptions}
                    onChange={(value) => {
                      setFieldValue("pair", value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center" gap={2} mt={2}>
                  <CSelect
                    classname={styles.select}
                    label={"间隔时间"}
                    placeholder="间隔时间"
                    options={intervalOptions}
                    onChange={(value) => {
                      setFieldValue("interval", value);
                    }}
                    description={`k线数据，选"5m 15m 30m 1h" 其中一个`}
                  />
                </Flex>
                <CommonLowBuyHighSellForm styles={styles} />

                <Flex alignItems="center" gap={2} mt={4}>
                  <CButton name="确认创建" onClick={onOpen} />
                </Flex>
              </Flex>
            </Form>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    确认创建
                  </AlertDialogHeader>

                  <AlertDialogBody>确定立即创建机器人吗？</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      取消
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        submitForm();
                      }}
                      ml={3}
                    >
                      确定
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        );
      }}
    </Formik>
  );
}
