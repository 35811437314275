import { api, ApiResponse } from "./base";
import {
  ClosePositionRequest,
  ClosePositionResponse,
  FetchOrderResponse,
  FetchPositionResponse,
  OpenPositionRequest,
  OpenPositionResponse,
  PairResponse,
  PerpBaseRequest,
} from "./types";

export const requestOpenPosition = async (payload: OpenPositionRequest): Promise<OpenPositionResponse> => {
  const res = await api.post<ApiResponse<OpenPositionResponse>>("/v2/contract/openPositions", payload);
  return res.data.data;
};
export const requestClosePosition = async (payload: ClosePositionRequest): Promise<ClosePositionResponse> => {
  const res = await api.post<ApiResponse<ClosePositionResponse>>("/v2/contract/closePositions", payload);
  return res.data.data;
};
export const fetchPosition = async (payload: PerpBaseRequest): Promise<FetchPositionResponse[]> => {
  const res = await api.post<ApiResponse<FetchPositionResponse[]>>("/v2/contract/fetchPositions", payload);
  return res.data.data;
};
export const fetchPerpBalance = async (payload: PerpBaseRequest): Promise<any> => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/balance", payload);
  return res.data.data;
};
export const fetchPerpOrders = async (payload: PerpBaseRequest): Promise<FetchOrderResponse[]> => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/openList", payload);
  return res.data.data;
};

export const fetchPairs = async () => {
  const res = await api.get<ApiResponse<PairResponse>>("/v2/contract/pairs");
  return res.data.data;
};

export const requestCancelOrder = async (
  payload: PerpBaseRequest & {
    orderId: string;
    type: string;
    symbol: string;
  }
): Promise<any> => {
  const res = await api.post<ApiResponse<any>>("/v2/contract/cancelOrder", payload);
  return res.data.data;
};
