import { Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { orderApi, strategiesApi } from "src/api";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";

type Props = {
  stragegyId?: number;
  hideFullScreenButton?: boolean;
};

export default function StatusPanel(props: Props) {
  const { stragegyId } = props;
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const localtion = useLocation();
  const exchangeAccountId = new URLSearchParams(localtion.search).get("exchangeAccountId");
  const isSelf = new URLSearchParams(localtion.search).get("isSelf");
  const statusQuery = useQuery({
    queryKey: ["status", props.stragegyId],
    queryFn: () => {
      if (props.stragegyId) {
        return strategiesApi.fetchStrategyStatus(stragegyId);
      }
      return orderApi.fetchOrdersV2("Manual");
    },
    refetchInterval: 5000,
    enabled: !!stragegyId,
  });
  let data = statusQuery.data || "";

  return (
    <>
      <Flex width="100%" flexDirection="column">
        {/* <ReactMarkdown>{data}</ReactMarkdown> */}
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </Flex>
    </>
  );
}
