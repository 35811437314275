import { api, ApiResponse } from "./base";
import {
  AccountResponse,
  ExchangeAccountResponse,
  GetBalanceItem,
  MultiGetBalanceResponse,
  MyInfoResponse,
  RoleResponse,
} from "./types";

export const fetchAccounts = async () => {
  const res = await api.get<ApiResponse<AccountResponse>>("/accounts", {
    params: {
      take: 10000,
      skip: 0,
    },
  });
  return res.data.data;
};

export const fetchRoles = async () => {
  const res = await api.get<ApiResponse<RoleResponse[]>>(`/accounts/self/roles`);
  return res.data.data;
};

export const fetchMyExchangeAccounts = async () => {
  const res = await api.get<ApiResponse<ExchangeAccountResponse>>("/exchangeAccounts/self", {
    params: {
      take: 10000,
      skip: 0,
    },
  });
  return res.data.data;
};

export const fetchMe = async () => {
  const res = await api.get<ApiResponse<MyInfoResponse>>("/accounts/self");
  return res.data.data;
};

export const fetchBalances = async (payload: { exchangeAccountId: number; pairId: number }) => {
  const res = await api.post<ApiResponse<GetBalanceItem[]>>(`/v2/orders/exchangeAccounts/getBalances`, {
    exchangeAccountId: payload.exchangeAccountId,
    pairId: payload.pairId,
  });
  return res.data.data;
};

export const fetchMultipleBalances = async (payload: { exchangeAccountId: number; pairId: number }) => {
  const res = await api.post<ApiResponse<MultiGetBalanceResponse[]>>("/v2/orders/exchangeAccounts/multiGetBalances", {
    exchangeAccountId: payload.exchangeAccountId,
    pairId: payload.pairId,
  });
  return res.data.data;
};

export const changePassword = async (payload: { newPassword: string }) => {
  const res = await api.post<ApiResponse<null>>("/v2/sys/updatePwd", payload);
  return res.data.data;
};

export const revokeExchangeAccount = async (payload: { perId: number }) => {
  const res = await api.post<ApiResponse<null>>("/v2/users/delExchangeAccountPermission", payload);
  return res.data.data;
};

export const deleteExchangeAccount = async (payload: { id: number }) => {
  const res = await api.post<ApiResponse<null>>("/v2/users/delExchangeAccount", payload);
  return res.data.data;
};
