import { Flex, Input, Switch, Text, Tooltip } from "@chakra-ui/react";
import { useField } from "formik";
import styles from "./CInput.module.scss";
import { QuestionIcon } from "@chakra-ui/icons";

const CSwitch = ({
  label,
  isChecked,
  onChange,
  classname,
  description,
  ...props
}: {
  label: string;
  onChange: (id: string, value: boolean) => void;
  isChecked: boolean;
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  classname?: string;
  description?: string;
}) => {
  const id = props.id || props.name;
  return (
    <Flex gap={1} flexDirection="column" alignItems="center" className={classname}>
      <Flex gap={2} alignItems="center" justifyContent="space-between" width="100%">
        <Text as="label" htmlFor={id} width="max-content" display={"flex"} alignItems={"center"} gap={1}>
          {label}
          {description && (
            <Tooltip label={description} aria-label="A tooltip">
              <QuestionIcon cursor="pointer" />
            </Tooltip>
          )}
        </Text>
        <Switch
          name={id}
          id={id}
          isChecked={isChecked}
          onChange={() => {
            onChange(id, !isChecked);
          }}
        />
      </Flex>
    </Flex>
  );
};

export default CSwitch;
