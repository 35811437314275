export const positionCols = [
  // contract: string;
  // symbol: string;
  // size: number;
  // leverage: string;
  // entry_price: string;
  // liq_price: string;
  // mark_price: string;
  // unrealised_pnl: string;
  // open_time: number;
  // side: string;
  {
    key: "contract",
    title: "币对",
  },
  {
    key: "size",
    title: "大小",
  },
  {
    key: "leverage",
    title: "杠杆",
  },
  {
    key: "entry_price",
    title: "开仓价格",
  },
  {
    key: "liq_price",
    title: "强平价格",
  },
  {
    key: "mark_price",
    title: "标记价格",
  },
  {
    key: "unrealised_pnl",
    title: "未实现盈亏",
  },
  {
    key: "open_time",
    title: "开仓时间",
  },
  {
    key: "side",
    title: "方向",
  },
];

export const orderCols = [
  {
    key: "id",
    title: "ID",
  },
  {
    key: "orderId",
    title: "订单ID",
  },
  {
    key: "create_time",
    title: "创建时间",
  },
  {
    key: "contract",
    title: "合约",
  },
  {
    key: "symbol",
    title: "币对",
  },
  {
    key: "size",
    title: "大小",
  },
  {
    key: "side",
    title: "方向",
  },
  {
    key: "price",
    title: "价格",
  },
  {
    key: "type",
    title: "类型",
  },
];

export const REFETCH_INTERVAL = 5 * 1000;
